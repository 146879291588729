import * as React from "react"
import { Link } from "gatsby"
import i18next from 'i18next'
import minilogo from '../images/fav.png';
import { QRCode } from 'react-qrcode-logo';

// markup
const NotFoundPage = () => {
  return (
    
    <div className="bg-white min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
      <div className="max-w-max mx-auto">
        <main className="sm:flex">
        <QRCode value="https://me.rejoiceapp.io" size="500" bgColor="#00ffff00" logoImage={minilogo} logoPadding={20} qrStyle='squares' logoOpacity={1} fgColor='#641b59' removeQrCodeBehindLogo={true} logoPaddingStyle="circle" />
          <p className="text-4xl font-extrabold text-indigo-600 sm:text-5xl">404</p>
          <div className="sm:ml-6">
            <div className="sm:border-l sm:border-gray-200 sm:pl-6">
              <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">{i18next.t('404.title')}</h1>
              <p className="mt-1 text-base text-gray-500">{i18next.t('404.caption')}</p>
            </div>
            <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
              <Link
                to="/"
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {i18next.t('404.back')}
              </Link>
              <Link
                href="/about"
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {i18next.t('404.about')}
              </Link>
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}

export default NotFoundPage
